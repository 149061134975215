<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-tabs pills>
      <b-tab title="Satışlarım Listesi" active>
        <b-card>
          <b-row>
            <b-col cols="8">
              <b-row>
                <b-col cols="12">
                  <h5>
                    Listedeki Toplam Satış Tutarı:
                    <b> {{ satistoplamTutar | formatCurrency }} TL</b>
                  </h5></b-col
                >
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h5>
                    Listedeki Toplam Ortalama Maliyet Tutarı:
                    <b> {{ maliyetToplamTutar | formatCurrency }} TL</b>
                  </h5></b-col
                >
              </b-row>
            </b-col>

            <b-col cols="4" class="text-right">
              <b-button @click="getStock()" variant="success">
                <feather-icon icon="RefreshCcwIcon" /> Yenile
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12"
              ><dx-data-grid
                id="dxDataGrid"
                :data-source="dataSource"
                :word-wrap-enabled="false"
                :remote-operations="false"
                column-resizing-mode="widget"
                :column-min-width="50"
                :show-borders="true"
                :show-row-lines="true"
                :row-alternation-enabled="false"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                height="650px"
              >
                <DxEditing
                  :allow-updating="false"
                  :allow-adding="false"
                  :allow-deleting="false"
                  mode="cell"
                ></DxEditing>
                <DxFilterRow :visible="true" />
                <DxFilterPanel :visible="true" />
                <DxFilterBuilderPopup :position="filterBuilderPopupPosition" />
                <DxHeaderFilter :visible="true" />

                <DxPaging :page-size="20" />

                <DxColumnFixing :enabled="true" />

                <DxColumnChooser :enabled="true" />
                <DxGroupPanel :visible="true" />
                <DxGrouping :auto-expand-all="true" />
                <DxExport :enabled="true" :allow-export-selected-data="true" />

                <DxGroupPanel :visible="true" />
                <DxSearchPanel
                  :visible="true"
                  :highlight-case-sensitive="true"
                />
                <DxGrouping :auto-expand-all="true" />
                <DxPager
                  :allowed-page-sizes="pageSizes"
                  :show-page-size-selector="true"
                />
                <DxPaging :page-size="100" />
                <dx-column
                  dataField="evrakNo"
                  caption="Satış"
                  :group-index="0"
                  sortOrder="desc"
                  group-cell-template="evrakTemplate"
                />

                <template #evrakTemplate="{ data }">
                  <b-row>
                    <b-col>
                      <h5>
                        Toplam Tutar:
                        {{ groupTotalPrice(data.data) | formatCurrency }} TL
                      </h5>
                    </b-col>
                  </b-row>
                </template>

                <dx-column dataField="fullName" caption="Satın Alan" />
                <dx-column dataField="product.name" caption="Satılan Ürün" />
                <dx-column
                  dataField="quantity"
                  caption="Miktar"
                  data-type="number"
                />
                <dx-column
                  dataField="unitPriceAverage"
                  caption="Birim Ortalama Maliyeti (TL)"
                  format="#,##0.###"
                  data-type="number"
                />
                <dx-column
                  dataField="product.sellPrice"
                  caption="Satış Fiyatı(TL)"
                  format="#,##0.###"
                  data-type="number"
                />
                <dx-column
                  dataField="totalPrice"
                  caption="Sattığım Fiyatı(TL)"
                  format="#,##0.###"
                  data-type="number"
                />

                <!-- selectedFilterOperation=">="   filterValue="2013/04/01"-->
                <dx-column
                  dataField="createDate"
                  caption="Oluşturma Tarihi"
                  dataType="date"
                  format="dd/MM/yyyy"
                  :width="150"
                /> </dx-data-grid
            ></b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab title="Satışlarım Grafik">
        <b-row>
          <b-col cols="12" md="6" sm="12">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-1">SATIŞ KÂR ORANI</h4>
                <b-card-text class="font-medium-5 mb-0">
                  <feather-icon
                    icon="HelpCircleIcon"
                    size="21"
                    class="text-muted cursor-pointer"
                  />
                </b-card-text>
              </b-card-header>

              <!-- apex chart -->
              <vue-apex-charts
                type="radialBar"
                height="245"
                :options="goalOverviewRadialBar.chartOptions"
                :series="goalOverviewRadialBar.series"
              />
              <b-row class="text-center mx-0">
                <b-col
                  cols="6"
                  class="border-top border-right d-flex align-items-between flex-column py-1"
                >
                  <b-card-text class="text-muted mb-0">
                    Toplam Satış Tutarı
                  </b-card-text>
                  <h4 class="font-weight-bolder mb-50">
                    {{ satistoplamTutar | formatCurrency }} TL
                  </h4>
                </b-col>
                <b-col
                  cols="6"
                  class="border-top border-right d-flex align-items-between flex-column py-1"
                >
                  <b-card-text class="text-muted mb-0">
                    Toplam Maliyet Tutarı
                  </b-card-text>
                  <h4 class="font-weight-bolder mb-50">
                    {{ maliyetToplamTutar | formatCurrency }} TL
                  </h4>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BButton,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BModal,
  VBModal,
  BFormCheckbox,
  BFormSpinbutton,
  BTab,
  BTabs,
  BCardHeader,
} from "bootstrap-vue";
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxLoadPanel,
  DxMasterDetail,
  DxColumnFixing,
  DxStateStoring,
  DxHeaderFilter,
  DxFilterPanel,
  DxColumnChooser,
  DxPager,
  DxEditing,
  DxPopup,
  DxLookup,
  DxForm,
  DxPaging,
  DxSearchPanel,
  DxFilterBuilderPopup,
  DxScrolling,
  DxRequiredRule,
  DxEmailRule,
  DxPatternRule,
  DxAsyncRule,
} from "devextreme-vue/data-grid";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    VBModal,
    BFormCheckbox,
    BFormSpinbutton,
    DxDataGrid,
    DxColumn,
    DxExport,
    DxSelection,
    DxGroupPanel,
    DxGrouping,
    DxFilterRow,
    DxLoadPanel,
    DxMasterDetail,
    DxColumnFixing,
    DxStateStoring,
    DxHeaderFilter,
    DxFilterPanel,
    DxColumnChooser,
    DxPager,
    DxEditing,
    DxPopup,
    DxLookup,
    DxForm,
    DxPaging,
    DxSearchPanel,
    DxFilterBuilderPopup,
    DxScrolling,
    DxRequiredRule,
    DxEmailRule,
    DxPatternRule,
    DxAsyncRule,
    ToastificationContent,
    BTab,
    BTabs,
    VueApexCharts,
    BCardHeader,
  },
  data() {
    return {
      pageSizes: [50, 100, 200, 500],
      filterBuilderPopupPosition: {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
      },
      show: false,
      dataSource: new DataSource({
        store: new ArrayStore({
          data: null,
        }),
      }),
      maliyetToplamTutar: 0,
      totalPrice: 0,
      costPrice: 0,
      yuzdeTotal: 0,
      goal_overview: {},
      goalOverviewRadialBar: {
        series: [0],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#51e5a8"],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "77%",
              },
              track: {
                background: "#ebe9f1",
                strokeWidth: "50%",
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: "#5e5873",
                  fontSize: "2.86rem",
                  fontWeight: "600",
                },
              },
            },
          },
          fill: {
            gradient: {
              shade: "success",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: "success",
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
    };
  },
  filters: {
    formatCurrency(value) {
      return new Intl.NumberFormat("tr-TR", {
        currency: "TRY",
      }).format(value);
    },
  },
  computed: {
    satistoplamTutar() {
      var list = [];
      var satirlar = this.dataSource._items;
      for (var i = 0; i < satirlar.length; i++) {
        var altSatirlar = [];
        altSatirlar = satirlar[i].items;
        for (var a = 0; a < altSatirlar.length; a++) {
          list.push(altSatirlar[a]);
        }
      }
      var total = 0;
      var maliyet = 0;

      for (var a = 0; a < list.length; a++) {
        total = Number(list[a].totalPrice) + Number(total);
        maliyet = Number(list[a].unitPriceAverage) + Number(maliyet);
      }
      this.totalPrice = total;

      this.maliyetToplamTutar = maliyet;
      this.yuzdeHesap();
      return total;
    },
  },
  created() {
    this.getStock();
  },
  methods: {
    yuzdeHesap() {
      var yuzde = 0;

      if (this.maliyetToplamTutar == 0 && this.totalPrice == 0) {
        yuzde = 0;
      } else if (this.maliyetToplamTutar == 0) {
        yuzde = 100;
      } else {
        yuzde =
          ((Number(this.totalPrice) - Number(this.maliyetToplamTutar)) /
            Number(this.maliyetToplamTutar)) *
          100;
      }
      yuzde = yuzde.toFixed(2);

      this.goalOverviewRadialBar.series = [yuzde];
    },
    groupTotalPrice(group) {
      return group.items[0].fullTotalPrice;
    },
    async getStock() {
      this.show = true;
      this.dataSource = new DataSource({
        store: new ArrayStore({
          data: null,
        }),
      });
      this.$http
        .get("Sells")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            var newRows = [];
            for (var i = 0; i < satirlar.length; i++) {
              for (var x = 0; x < satirlar[i].rows.length; x++) {
                satirlar[i].rows[x].fullName = satirlar[i].client.fullName;
                satirlar[i].rows[x].phone = satirlar[i].client.phone;
                satirlar[i].rows[x].createDate = satirlar[i].createDate;
                satirlar[i].rows[x].evrakNo = i;
                satirlar[i].rows[x].fullTotalPrice = satirlar[i].totalPrice;

                newRows.push(satirlar[i].rows[x]);
              }
            }

            this.dataSource = new DataSource({
              store: new ArrayStore({
                data: newRows,
              }),
            });
            this.getBuys();
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },

    //Mal Kabuller
    async getBuys() {
      this.$http
        .get("buys")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var data = response.data;

            const groupedData = data.reduce((acc, curr) => {
              if (!acc[curr.productId]) {
                acc[curr.productId] = { totalQuantity: 0, totalPrice: 0 };
              }
              acc[curr.productId].totalQuantity += curr.quantity;
              acc[curr.productId].totalPrice += curr.unitPrice * curr.quantity;
              return acc;
            }, {});

            // Ortalamaları hesapla ve yeni bir dizi oluştur
            const averagesArray = Object.keys(groupedData).map((key) => ({
              productId: parseInt(key),
              unitPriceAverage:
                groupedData[key].totalPrice / groupedData[key].totalQuantity,
            }));

            var satirlar = this.dataSource.store()._array;

            for (var i = 0; i < satirlar.length; i++) {
              for (var a = 0; a < averagesArray.length; a++) {
                if (satirlar[i].product.id == averagesArray[a].productId) {
                  satirlar[i].unitPriceAverage =
                    averagesArray[a].unitPriceAverage;
                }
              }
            }
            this.dataSource.reload();
            this.maliyetToplam();
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
  },
};
</script>

<style></style>
